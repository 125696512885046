<div class="backdrop" *ngIf="show" (click)="backdropClick()"></div>
<div class="slide-panel" [class.historyvisible]="show">
  <div
    class="align-items-center bg-white border-bottom d-flex justify-content-between position-sticky px-4 py-2 top-0 z-index-9">
    <h3 class="mb-0 c-heading h-color">{{historyTitle}}</h3>
    <div class="d-flex align-items-center">
      <select (change)="changeOrder($event)" [(ngModel)]="selectedOption" class="history-order-select mr-4">
        <option>Newest to oldest</option>
        <option>Oldest to newest</option>
      </select>
      <div (click)="historyClose()" class="cursor-pointer">
        <i class="icon-close"></i>
      </div>
    </div>
  </div>

  <div class="s-l" *ngIf="showScanlogs && historyArray.module != 'Job'">
    <span class="btn-main text-decoration-underline cursor-pointer" (click)="openScanLogs()">View Scan Log</span>
  </div>

  <div class="timeline-wrapper">
    <div class="timeline">
      <!-- Old design maybe gonna need it in future -->
      <!-- <div class="container" *ngFor="let item of historyArray">
        <label class="mb-4 d-color">{{ item.event }}</label>
        <div class="task_container" *ngFor="let task of item.value">
          <label class="mb-2 c-heading l-color">{{ task.task_type }}</label>
          <button disabled [attr.status]="task.status" mat-stroked-button class="c-status f-r">
            {{ (task.status) ? (task.status | titlecase) : '-'}}
          </button>
          <div class="content" *ngFor="let content of task.details">
            <div class="d-flex align-items-start justify-content-between">
              <div>
                <p>{{ content.updated_at }} {{ insertSpaces(content.event)}} <a>{{ '[' + content.module_number + ']' }}</a></p>
                <h5> {{ content.user_name }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- New design a/c to figma -->
      <div class="container">
        <div class="d-flex justify-content-between" *ngIf="showScanlogs">
          <div class="">
            <label class="c-heading l-color">{{historyArray?.module}} ID #</label>
            <p class="container-type">{{historyArray?.task_id || '-' }}</p>
          </div>
          <!-- <div class="workflow-container">
            <label class="c-heading l-color">Workflow</label>
            <p class="container-type">{{historyArray.workflow_type || '-'}}</p>
          </div> -->
          <div class="workflow-container max-w-110 min-w-110" [ngClass]="getWorkflowClass()">
            <label class="c-heading l-color">Workflow</label>
            <p class="container-type">{{ historyArray.workflow_type || '-' }}</p>
          </div>
        </div>
        <!-- <div class="task_container">
          <label class="mb-2 c-heading l-color">Job ID #</label>
          <button disabled mat-stroked-button class="c-status f-r">
            Module
          </button>
          <div class="content">
            <div class="d-flex align-items-start justify-content-between">
              <div>
                <p>TI - 00005</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="task_container" *ngFor="let task of historyArray?.historyData">
          <label class="mb-2 c-heading l-color">{{ task.event }}</label>
          <button *ngIf="task?.status" disabled [attr.status]="task.status" mat-stroked-button class="c-status f-r">
            {{ (task.status) ? (task.status | titlecase) : '-'}}
          </button>
          <div class="content">
            <div class="d-flex align-items-start justify-content-between">
              <div>
                <p>{{ task?.details?.caused_at }}</p>
                <p> {{ task?.details?.caused_by }}</p>
                <ng-container  *ngIf="task.details?.actions?.length">
                <p *ngFor="let action of task.details.actions">
                  Action : {{ action.action_message }} <br>
                  {{getRouteName(action.action_module)}}: <span class="link"
                    (click)="redirect(action.record_id, action.action_module, action?.count_type)">
                    {{ action.action_record_id }}
                  </span>
                </p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- footer button -->
  <footer id="history-footer" class="bottom-0 position-sticky bg-white border-top z-index-9">
    <button mat-flat-button class="btn-outlined d-flex align-items-center justify-content-center" (click)="historyClose()">Cancel</button>
  </footer>

  <div class="history-overlay" (click)="historyClose()"></div>
</div>
