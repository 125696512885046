<!-- <ngx-ui-loader></ngx-ui-loader> -->
<ngx-ui-loader
  [fgsTemplate]="foregroundSpinner"
></ngx-ui-loader>
<ng-template #foregroundSpinner>
  <div class="relative">
    <img class="face-loader" src="/../../assets/images/face-gif-unscreen.gif" alt="Login image" width="47">
    <img src="/../../assets/images/Circle-Loader.gif" alt="Login image" width="96">
  </div>
</ng-template>


<div class="d-md-none d-lg-none d-flex m-3 justify-content-end">
  <mat-icon (click)="toggleSidebar()">view_headline</mat-icon>
</div>
<!---------------------------SIDE BAR FOR SERVICE PROVIDER--START---------------------------->
<div
  *ngIf="service.profileDetail?.account_type === 'service_provider'"
  class="sp d-none d-print-none sidebar sidebar-fixed"
>
  <ng-template ngFor let-i="index" let-item [ngForOf]="MenuData">
    <div
      class="text-center nav-item cursor-pointer"
      [ngClass]="[
        service.profileDetail?.account_type === 'service_provider' &&
        i == menuStartingIndex
          ? 'sptoggle-active'
          : '',
        i == 0 ? 'mt-82' : ''
      ]"
      (click)="sptoggle(i)"
    >
      <img
        [ngClass]="{
          'sptoggle-active':
            service.profileDetail?.account_type === 'service_provider' &&
            i == menuStartingIndex
        }"
        [src]="item.image"
        alt=""
      />
      <div
        class="mb-35 sp-menu"
        [ngClass]="{
          'sptoggle-active':
            service.profileDetail?.account_type === 'service_provider' &&
            i == menuStartingIndex
        }"
      >
        {{ item.name }}
      </div>
    </div>
  </ng-template>
</div>
<!---------------------------SIDE BAR FOR SERVICE PROVIDER--END---------------------------->
<!---------------------------MENU SIDE BAR--START----------------------------------------->
<div [@collapseExpand]="isCollapsed ? 'collapsed' : 'expanded'" class="sidebar">
  <c-sidebar
    *ngIf="!isCollapsed"
    #sidebar="cSidebar"
    [ngClass]="[
      service.profileDetail?.account_type === 'service_provider'
        ? 'sptoggle'
        : 'clientTogglesptoggle',
      hideSideMenu ? 'sidebarHidden' : '',
      !isSidebarOpen ? 'resp-sidebar-none' : 'resp-sidebar-block'
    ]"
    class="position-sidebar d-print-none sidebar sidebar-fixed resp-sidebar"
    id="sidebar"
    visible
  >
    <div
      class="SPLogo resp-close-sidebar"
      [ngClass]="hideSideMenu ? 'toggledIcon' : 'completeIcon'"
      *ngIf="service.profileDetail?.account_type === 'service_provider'"
    >
      <div class="custom-class">
        <c-sidebar-brand
          *ngIf="!hideSideMenu"
          [brandFull]="{
            src: '../../../assets/images/wize-logo.png',
            width: 80,
            style:
              'text-align: left; margin-bottom: 0px !important; margin-top: 2px !important;',
            alt: 'SHTIKS Logo'
          }"
          [brandNarrow]="{
            src: 'assets/img/brand/coreui-signet-white.svg',
            width: 135,
            height: 70,
            alt: 'SHTIKS Logo'
          }"
          routerLink="./Dashboard"
        >
        </c-sidebar-brand>

        <c-sidebar-brand
          *ngIf="hideSideMenu"
          [brandFull]="{
            src: '../../../assets/images/ToggleLogoSP.svg',
            width: 50,
            height: 70,
            alt: 'SHTIKS Logo'
          }"
          [brandNarrow]="{
            src: 'assets/img/brand/coreui-signet-white.svg',
            width: 50,
            height: 70,
            alt: 'SHTIKS Logo'
          }"
          routerLink="./Dashboard"
        >
        </c-sidebar-brand>
      </div>
      <button
        mat-icon-button
        (click)="closeSidebar()"
        class="d-none d-resp-block"
        style="font-size: 24px; color: black; margin-right: 7px"
      >
        <mat-icon aria-hidden="false" aria-label="Close">close</mat-icon>
      </button>
    </div>
    <div
      class="SPLogo resp-close-sidebar"
      *ngIf="
        service.profileDetail?.account_type === 'client' ||
        service.profileDetail?.account_type === 'admin'
      "
    >
      <div class="custom-class">
        <c-sidebar-brand
          *ngIf="!hideSideMenu"
          [brandFull]="{
            src: '../../../assets/images/wize-logo.png',
            width: 115,
            style:
              'text-align: center; margin-left: 40px; margin-bottom: 0px !important; margin-top: 2px !important;',
            alt: 'SHTIKS Logo'
          }"
          [brandNarrow]="{
            src: 'assets/img/brand/coreui-signet-white.svg',
            width: 135,
            height: 31,
            alt: 'SHTIKS Logo'
          }"
          routerLink="./Dashboard"
        >
        </c-sidebar-brand>
        <c-sidebar-brand
          *ngIf="hideSideMenu"
          [brandFull]="{
            src: '../../../assets/images/MioMarinoicon.png',
            width: 50,
            height: 31,
            alt: 'SHTIKS Logo'
          }"
          [brandNarrow]="{
            src: 'assets/img/brand/coreui-signet-white.svg',
            width: 50,
            height: 31,
            alt: 'SHTIKS Logo'
          }"
          routerLink="./Dashboard"
        >
        </c-sidebar-brand>
      </div>
      <button
        mat-icon-button
        (click)="closeSidebar()"
        class="d-none d-resp-block"
        style="font-size: 24px; color: black; margin-right: 7px"
      >
        <mat-icon aria-hidden="false" aria-label="Close">close</mat-icon>
      </button>
    </div>

    <div
      style="max-height: 100% !important; overflow-x: hidden; overflow-y: auto;"
    >
      <aside
        id="sidebar"
        class="sidebar resp-sidemenu"
        [ngClass]="{
          'resp-sidebar-none': !isSidebarOpen,
          'resp-sidebar-block': isSidebarOpen
        }"
      >
        <ul *ngIf="!hideSideMenu" class="sidebar-nav" id="sidebar-nav">
          <ng-container
            *ngFor="
              let item of MenuData[menuStartingIndex]?.links;
              first as isFirst;
              last as isLast;
              let elementIndex = index
            "
          >
            <li
              class="nav-item"
              routerLinkActive="nav-link-parent"
              [routerLinkActiveOptions]="{ exact: false }"
              [ngClass]="{ 'active': item.isOpen }"
            >
                    <a
                    class="level1"
                    (click)="toggleMenu(item)"
                     [ngClass]="{ 'active': item.isOpen }"
                     >
                      <i [class]="item.iconClass"></i>
                      {{ item.name }}
                      <span *ngIf="item.links?.length > 0" class="bi ms-auto" [ngClass]="item.isOpen ? 'bi-chevron-up' : 'bi-chevron-down'"></span>
                    </a>
                    <ul
                    *ngIf="item.isOpen && item.links?.length > 0"
                     class="nav-content"
                     >
                      <li *ngFor="let subItem of item.links"
                       routerLinkActive="active"
                       >
                        <a  [routerLinkActiveOptions]="{ exact: false }"
                        [ngClass]="{ activate: activeWarehouse }"
                        [routerLink]="subItem?.route"
                        routerLinkActive="active" (click)="navigateTo(subItem?.route)">
                          <i class="bi bi-circle" routerLinkActive="active"></i>
                          {{ subItem.name }}
                        </a>
                      </li>
                    </ul>
                  
            </li>
          </ng-container>
        </ul>

        <ul *ngIf="hideSideMenu" class="sidebar-nav" id="sidebar-nav">
          <ng-container
            *ngFor="
              let item of MenuData[menuStartingIndex]?.links;
              first as isFirst;
              last as isLast;
              let elementIndex = index
            "
          >
            <li
              class="nav-item"
              routerLinkActive="nav-link-parent"
              [routerLinkActiveOptions]="{ exact: false }"
              [ngClass]="{ 'active': item.isOpen }"

            >
              <a
                (click)="onToggleBtnClick()"
                class="level1"
                *ngIf="!item.links || item.links.length < 1"
                [routerLink]="item?.route"
                routerLinkActive="nav-link"
                [routerLinkActiveOptions]="{ exact: false }"
                (click)="openListAllStatus()"
              >
                <i [class]="item.iconClass"></i>
              </a>

              <a
                (click)="onToggleBtnClick()"
                class="level1"
                [routerLinkActiveOptions]="{ exact: false }"
                *ngIf="item.links && item.links.length > 0"
                routerLinkActive="nav-link"
                [routerLink]="item.route"
                (click)="routeToFirstNestedItem(item.links[0].route)"
                (click)="openListAllStatus()"
              >
                <i [class]="item.iconClass"></i>
              </a>
            </li>
          </ng-container>
        </ul>
      </aside>
    </div>

    <a routerLink="./" (click)="logout()" class="resp-logout d-none">
      <mat-icon class="resp-logout-font">power_settings_new</mat-icon>
      <h1 class="mb-1 resp-logout-font">Logout</h1>
    </a>

    <!-- <div>
    <button *ngIf="!hideSideMenu" class="toggleButtonOpen" style="margin-left: 10px; margin-bottom: 10px" (click)="onToggleBtnClick()">
      <img
        class="mr-12"
        src="../../../assets/images/ToggleVector.png"
        srcset=""
      />
      Toggle Sidebar
    </button>
    <button *ngIf="hideSideMenu" class="toggleButtonClose" style="margin-left: 10px; margin-bottom: 10px" (click)="onToggleBtnClick()">
      <img
        src="../../../assets/images/ToggleVectorClose.png"
        srcset=""
      />
    </button>
  </div> -->
  </c-sidebar>

  <!-- Collapse SideBar Start -->
  <c-sidebar
    *ngIf="isCollapsed"
    #sidebar="cSidebar"
    [ngClass]="[
      service.profileDetail?.account_type === 'service_provider'
        ? 'sptoggle'
        : 'clientTogglesptoggle',
      hideSideMenu ? 'sidebarHidden' : '',
      !isSidebarOpen ? 'resp-sidebar-none' : 'resp-sidebar-block'
    ]"
    class="position-sidebar w-60 d-print-none sidebar sidebar-fixed resp-sidebar collapsed_sidebar"
    id="sidebar"
    visible
  >
    <div
      class="SPLogo resp-close-sidebar"
      [ngClass]="hideSideMenu ? 'toggledIcon' : 'completeIcon'"
      *ngIf="service.profileDetail?.account_type === 'service_provider'"
    >
      <div class="custom-class">
        <c-sidebar-brand
          class="m-0"
          *ngIf="!hideSideMenu"
          [brandFull]="{
            src: '../../../assets/images/guide-logo.png',
            width: 45,
            alt: 'SHTIKS Logo'
          }"
          [brandNarrow]="{
            src: 'assets/img/brand/coreui-signet-white.svg',
            width: 40,
            height: 70,
            alt: 'SHTIKS Logo'
          }"
          routerLink="./Dashboard"
        >
        </c-sidebar-brand>

        <c-sidebar-brand
          *ngIf="hideSideMenu"
          [brandFull]="{
            src: '../../../assets/images/ToggleLogoSP.svg',
            width: 50,
            height: 70,
            alt: 'SHTIKS Logo'
          }"
          [brandNarrow]="{
            src: 'assets/img/brand/coreui-signet-white.svg',
            width: 50,
            height: 70,
            alt: 'SHTIKS Logo'
          }"
          routerLink="./Dashboard"
        >
        </c-sidebar-brand>
      </div>
      <!-- (click)="closeSidebar()" -->
      <button
        mat-icon-button
        class="d-none d-resp-block"
        style="font-size: 24px; color: black; margin-right: 7px"
      >
        <mat-icon aria-hidden="false" aria-label="Close">close</mat-icon>
      </button>
    </div>
    <div
      class="SPLogo resp-close-sidebar"
      *ngIf="
        service.profileDetail?.account_type === 'client' ||
        service.profileDetail?.account_type === 'admin'
      "
    >
      <div class="custom-class">
        <c-sidebar-brand
          class="m-0"
          *ngIf="!hideSideMenu"
          [brandFull]="{
            src: '../../../assets/images/guide-logo.png',
            width: 45,
            alt: 'SHTIKS Logo'
          }"
          [brandNarrow]="{
            src: 'assets/img/brand/coreui-signet-white.svg',
            width: 40,
            height: 31,
            alt: 'SHTIKS Logo'
          }"
          routerLink="./Dashboard"
        >
        </c-sidebar-brand>
        <c-sidebar-brand
          *ngIf="hideSideMenu"
          [brandFull]="{
            src: '../../../assets/images/MioMarinoicon.png',
            width: 50,
            height: 31,
            alt: 'SHTIKS Logo'
          }"
          [brandNarrow]="{
            src: 'assets/img/brand/coreui-signet-white.svg',
            width: 50,
            height: 31,
            alt: 'SHTIKS Logo'
          }"
          routerLink="./Dashboard"
        >
        </c-sidebar-brand>
      </div>
      <!-- (click)="closeSidebar()" -->
      <button
        mat-icon-button
        class="d-none d-resp-block"
        style="font-size: 24px; color: black; margin-right: 7px"
      >
        <mat-icon aria-hidden="false" aria-label="Close">close</mat-icon>
      </button>
    </div>

    <perfect-scrollbar
      style="max-height: 100% !important"
      [config]="perfectScrollbarConfig"
    >
      <aside
        id="sidebar"
        class="sidebar resp-sidemenu"
        [ngClass]="{
          'resp-sidebar-none': !isSidebarOpen,
          'resp-sidebar-block': isSidebarOpen
        }"
      >
        <!-- (click)="closeSidebar()" -->
        <ul
          *ngIf="!hideSideMenu"
          [ngClass]="{ 'cursor-default': isCollapsed }"
          class="sidebar-nav"
          id="sidebar-nav"
        >
          <ng-container
            *ngFor="
              let item of MenuData[menuStartingIndex]?.links;
              first as isFirst;
              last as isLast;
              let elementIndex = index
            "
          >
            <li
              class="nav-item"
              routerLinkActive="nav-link-parent"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <a
                class="level1 bg-transparent"
                [ngClass]="{ 'cursor-default font-1_5rem': isCollapsed }"
                *ngIf="!item.links || item.links.length < 1"
                [routerLink]="item?.route"
                routerLinkActive="nav-link"
                [routerLinkActiveOptions]="{ exact: false }"
                [matTooltip]="item.name"
                [matTooltipPosition]="'right'"
                matTooltipClass="custom-tooltip"
              >
                <i [class]="item.iconClass" (click)="routeToFirstNestedItem(item.links[0].route);"></i>
              </a>

              <a
                class="level1 bg-transparent"
                [ngClass]="{ 'font-1_5rem': isCollapsed }"
                *ngIf="item.links && item.links.length > 0"
                [matTooltip]="item.name"
                [routerLink]="item?.route"
                [matTooltipPosition]="'right'"
                routerLinkActive="nav-link"
                [routerLinkActiveOptions]="{ exact: false }"
                matTooltipClass="custom-tooltip"
              >
                <i [class]="item.iconClass" (click)="routeToFirstNestedItem(item.links[0].route);"></i>
              </a>

              
            </li>
          </ng-container>
        </ul>

        <ul *ngIf="hideSideMenu" class="sidebar-nav" id="sidebar-nav">
          <ng-container
            *ngFor="
              let item of MenuData[menuStartingIndex]?.links;
              first as isFirst;
              last as isLast;
              let elementIndex = index
            "
          >
            <li
              class="nav-item"
              routerLinkActive="nav-link-parent"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <!-- (click)="onToggleBtnClick()" -->
              <a
                class="level1 bg-transparent"
                *ngIf="!item.links || item.links.length < 1"
                [routerLink]="item?.route"
                routerLinkActive="nav-link"
                [routerLinkActiveOptions]="{ exact: false }"
                [matTooltip]="item.name"
                [matTooltipPosition]="'right'"
                matTooltipClass="custom-tooltip"
              >
                <!-- (click)="openListAllStatus()" -->
                <i [class]="item.iconClass"></i>
              </a>

              <!-- (click)="onToggleBtnClick()" -->
              <a
                class="level1 bg-transparent"
                [routerLinkActiveOptions]="{ exact: false }"
                *ngIf="item.links && item.links.length > 0"
                routerLinkActive="nav-link"
                [routerLink]="item.route"
                [matTooltip]="item.name"
                [matTooltipPosition]="'right'"
                matTooltipClass="custom-tooltip"
              >
                <!-- (click)="openListAllStatus()" -->
                <!-- (click)="routeToFirstNestedItem(item.links[0].route)" -->
                <i [class]="item.iconClass"></i>
              </a>
            </li>
          </ng-container>
        </ul>
      </aside>
    </perfect-scrollbar>

    <a routerLink="./" (click)="logout()" class="resp-logout d-none">
      <mat-icon class="resp-logout-font">power_settings_new</mat-icon>
      <h1 class="mb-1 resp-logout-font">Logout</h1>
    </a>

    <!-- <div>
    <button *ngIf="!hideSideMenu" class="toggleButtonOpen" style="margin-left: 10px; margin-bottom: 10px" (click)="onToggleBtnClick()">
      <img
        class="mr-12"
        src="../../../assets/images/ToggleVector.png"
        srcset=""
      />
      Toggle Sidebar
    </button>
    <button *ngIf="hideSideMenu" class="toggleButtonClose" style="margin-left: 10px; margin-bottom: 10px" (click)="onToggleBtnClick()">
      <img
        src="../../../assets/images/ToggleVectorClose.png"
        srcset=""
      />
    </button>
  </div> -->
  </c-sidebar>
  <!-- Collapse SideBar End -->
</div>

<!---------------------------MENU SIDE BAR--END-------------------------------------->
<!--main-->
<!-- <ngx-ui-loader></ngx-ui-loader> -->
<div
  class="resp-p-0 wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
  [style.padding-left]="calculatePadding()"
  [ngClass]="
    service.profileDetail?.account_type === 'service_provider'
      ? hideSideMenu
        ? 'SPcontentSectionSizeOnMenuHide'
        : 'sptoggle'
      : hideSideMenu
      ? 'ClientcontentSectionSizeOnMenuHide'
      : 'clientTogglesptoggle'
  "
>
  <!--app-header-->
  <!-- <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar"></app-default-header> -->
  <div
    *ngIf="isCollapsed"
    class="position-collapsed pointer"
    (click)="toggleCollapse()"
  >
  <button class="toggleButtonOpen">
    <i class="ic-chevron-right"></i>
  </button>
  </div>
  <div
    *ngIf="!isCollapsed"
    class="position-notCollapsed pointer"
    (click)="toggleCollapse()"
  >
  <button class="toggleButtonClose">
    <i class="ic-chevron-left"></i>
  </button>
  </div>
  <app-default-header
    class="d-none d-md-block d-lg-block d-print-none align-items-center header header-sticky"
    position="sticky"
    sidebarId="sidebar"
  >
  </app-default-header>
  <!--app-body-->
  <div class="body flex-grow-1 bg-primary px-2 scroll-custom">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet></router-outlet>
      <app-custom-snackbar></app-custom-snackbar>
      <app-alert></app-alert>
      <app-history></app-history>
      <!-- <ng-container *ngIf="this.service.setBaseUrl('').includes('dev')"><app-chatbot></app-chatbot></ng-container> -->
      <ng-container><app-chatbot></app-chatbot></ng-container>
    </c-container>
  </div>
  <!--app footer-->
  <!-- <app-default-footer></app-default-footer> -->
</div>
