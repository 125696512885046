import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScanLogsService } from './scan-logs.service';
import { ConfigService } from 'src/app/services/config.service';
import { AlertService } from '../alert/alert.service';
import { CommonService } from 'src/app/services/comman.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export interface DialogData {
  scanLogs: any;
  task_id: number;
  detailmode: boolean;
}

export interface ScanLog {
  action: string;
  event: string;
  field: string;
  reason: string | null;
  status: string;
  user_name: string;
  value: string;
  created_at: string;
}
@Component({
  selector: 'app-scan-logs',
  templateUrl: './scan-logs.component.html',
  styleUrls: ['./scan-logs.component.scss']
})
export class ScanLogsComponent implements OnInit {
  scanLogs: any;
  scanLogsTableData: ScanLog[];
  firstRecord: any;
  dateArray: string[] = [];
  selectedOption: string = 'Newest to oldest';
  displayedColumnsforPOLines: string[] = [
    'user_name',
    'date_time',
    'action',
    'value',
    'status',
    'reason'
  ];

  constructor(public dialogRef: MatDialogRef<ScanLogsComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public service: ConfigService, public alert: AlertService, private commanService: CommonService, private NgxLoader: NgxUiLoaderService) {}

  doaction() {
    this.dialogRef.close(this.scanLogs);
  }

  ngOnInit(): void {
    this.scanLogs =  this.data.scanLogs;
    this.scanLogsTableData = this.data.scanLogs.scan_logs;
    this.firstRecord = this.data.scanLogs.scan_logs[0];
  }

  changeOrder(event: Event) {
    const selectedValue = this.selectedOption === 'Newest to oldest' ? 'desc' : 'asc';

    // Call the service method to fetch sorted data
    this.getScanlogs(this.data.task_id, selectedValue);
  }

  async getScanlogs(id: number, sort: string) {
    let scanlogApi = `/api/get-scan-logs`;
    this.NgxLoader.start();
    const response: any = await this.service.getApi(
      scanlogApi,
      {
        "task_id": id,
        "sort_by": sort
      },
      {},
      'wms_service'
     );
     if (response.status && response?.data) {
      this.scanLogs = response.data;
      this.scanLogsTableData = response.data.scan_logs;
      this.NgxLoader.stop();
    } else {
      this.alert.show('Scan Log Not Found.', 'error');
      this.NgxLoader.stop();
      return;
    }
  }

  formatTaskId(task_id: number | undefined): string {
    return task_id ? 'TI-' + task_id.toString().padStart(6, '0') : '';
  }

  formatDate(date: string | Date): string {
    return new Date(date).toLocaleString();
  }

  async exportLogs() {
    try {
      this.NgxLoader.start();

      const filteredScanLogsData = this.scanLogsTableData.map((log: ScanLog) => ({
        "User Name": log.user_name,
        "Date & Time": log.created_at,
        "Action": log.action,
        "Field": log.field,
        "Value": log.value,
        "Status": log.status,
        "Reason": log.reason,
      }));

      if (this.scanLogs) {
        this.commanService.downloadJSONtoCSVFile(filteredScanLogsData, `${this.firstRecord.event} Scan Logs`);
      } else {
        this.alert.show('No data record found.', 'error');
      }
    } catch (error) {
      this.alert.show('No data record found.', 'error');
    } finally {
      this.NgxLoader.stop();
    }
  }

}
