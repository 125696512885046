import { ElModule } from 'element-angular';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  CommonModule,
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultFooterComponent, DefaultHeaderComponent } from './containers';
import {
  AvatarModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FooterModule,
  FormModule,
  GridModule,
  HeaderModule,
  ListGroupModule,
  ProgressModule,
  SharedModule,
  SidebarModule,
  UtilitiesModule,
  TabsModule,
  NavModule,
} from '@coreui/angular';

import { IconModule, IconSetService } from '@coreui/icons-angular';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigService } from './services/config.service';
import { AuthguardGuard } from './services/authguard.guard';
import { Interceptor } from './services/interceptor';
import {
  MatDateFormats,
  MAT_NATIVE_DATE_FORMATS,
} from '@angular/material/core';
import {
 MatDialogRef,
} from '@angular/material/dialog';
import { AppRouteService } from './pages/shared/app-route.service';
import { BreadCrumbService } from './pages/shared/breadcrumb.service';
import {
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  PB_DIRECTION,
  POSITION,
  SPINNER,
} from 'ngx-ui-loader';
import { CustomSnackbarComponent } from './pages/shared/custom-snackbar/custom-snackbar.component';
import { AlertComponent } from './pages/shared/alert/alert.component';
import { LoginComponent } from './views/pages/login/login.component';
import { NewPasswordComponent } from './views/pages/new-password/new-password.component';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { CloseScrollStrategy, Overlay, OverlayModule } from '@angular/cdk/overlay';
import { LayoutShtiksComponent } from './containers/default-layout/layout-shtiks/layout-shtiks.component';
import { HistoryComponent } from './pages/shared/history/history.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { JoyrideModule, JoyrideStepComponent } from 'ngx-joyride';
import { ChatbotComponent } from './pages/shared/chatbot/chatbot.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 60,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.chasingDots, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness,
};

const APP_CONTAINERS = [
  DefaultFooterComponent,
  DefaultHeaderComponent,
  CustomSnackbarComponent,
  LoginComponent,
  NewPasswordComponent,
  AlertComponent,
  HistoryComponent,
  ChatbotComponent
];

@NgModule({
  declarations: [AppComponent, ...APP_CONTAINERS, LayoutShtiksComponent],
  imports: [
    OverlayModule,
    NgSelectModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    JoyrideModule.forRoot(),
    AvatarModule,
    BreadcrumbModule,
    FooterModule,
    DropdownModule,
    GridModule,
    HeaderModule,
    SidebarModule,
    IconModule,
    PerfectScrollbarModule,
    NavModule,
    ButtonModule,
    FormModule,
    UtilitiesModule,
    ButtonGroupModule,
    ReactiveFormsModule,
    SidebarModule,
    SharedModule,
    TabsModule,
    ProgressModule,
    BadgeModule,
    ListGroupModule,
    CardModule,
    ElModule,
    MaterialModule,
    NgxUiLoaderModule,
    
    //NgxUiLoaderRouterModule,
    //NgxUiLoaderHttpModule,
    NgxUiLoaderModule.forRoot({
      bgsColor: '#003050',
      bgsOpacity: 0.3,
      bgsPosition: 'bottom-right',
      bgsSize: 60,
      bgsType: 'ball-spin-clockwise',
      blur: 8,
      delay: 0,
      fastFadeOut: true,
      fgsColor: '#ffffff',
      fgsPosition: 'center-center',
      fgsSize: 60,
      fgsType: 'three-strings',
      gap: 24,
      logoPosition: 'center-center',
      logoSize: 120,
      logoUrl: '',
      // "logoUrl": "../assets/images/NEW Preloader.gif",
      masterLoaderId: 'masterid',
      overlayBorderRadius: '0',
      overlayColor: 'rgba(153, 153, 153, 0.8)',
      pbColor: '#003050',
      pbDirection: 'ltr',
      pbThickness: 3,
      hasProgressBar: true,
      // text: 'Loading...',
      textColor: '#FFFFFF',
      textPosition: 'center-center',
      maxTime: -1,
      minTime: 300,
    }),
    
    //NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
  ],
  exports: [
    NgSelectModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    AvatarModule,
    BreadcrumbModule,
    FooterModule,
    DropdownModule,
    GridModule,
    HeaderModule,
    SidebarModule,
    IconModule,
    PerfectScrollbarModule,
    NavModule,
    ButtonModule,
    FormModule,
    UtilitiesModule,
    ButtonGroupModule,
    ReactiveFormsModule,
    SidebarModule,
    SharedModule,
    CustomSnackbarComponent,
    AlertComponent,
    TabsModule,
    ProgressModule,
    BadgeModule,
    ListGroupModule,
    CardModule,
    ElModule,
    NgxUiLoaderModule,
    //NgxUiLoaderRouterModule,
    //NgxUiLoaderHttpModule,
  ],
  entryComponents: [JoyrideStepComponent],
  providers: [
    AuthguardGuard,
    DatePipe,
    Interceptor,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    // { provide: RouteReuseStrategy, useClass: HashLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    IconSetService,
    Title,
    ConfigService,
    AppRouteService,
    BreadCrumbService,
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

export const GRI_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit',
    } as Intl.DateTimeFormatOptions,
  },
};

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}
