import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { AlertService } from 'src/app/pages/shared/alert/alert.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export interface panelData {
  title: any;
  name: any;
  route: any;
  links: [];
}

@Component({
  selector: 'app-layout-shtiks',
  templateUrl: './layout-shtiks.component.html',
  styleUrls: ['./layout-shtiks.component.scss'],
  animations: [
    trigger('collapseExpand', [
      state(
        'collapsed',
        style({
          width: '60px', // Width when collapsed
        })
      ),
      state(
        'expanded',
        style({
          width: '20rem', // Width when expanded
        })
      ),
      transition('collapsed <=> expanded', animate('300ms ease-in-out')),
    ]),
  ],
})
export class LayoutShtiksComponent implements OnInit {
  hideSideMenu = false;
  isCollapsed: boolean = false;
  public MenuData: any;
  masterData: any = [];
  data: any;

  menuStartingIndex = 0;
  isSidebarOpen: boolean = false;
  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  public MenuDataAccount = [
    {
      title: 'Client',
      name: '',
      route: '',
      image: '',
      links: [
        {
          label: 'Account Management',
          name: 'Account Management',
          route: '/account-management/company-account',
          image: '../../../assets/images/Vector (5).png',
          iconClass: 'icon-settings',
          links: [
            {
              name: 'Company Account',
              route: '/account-management/company-account',
              image: '',
              links: [],
            },
          ],
        },
      ],
    },
  ];

  public MenuDataClient = [
    {
      title: 'Client',
      name: '',
      route: '',
      image: '',
      links: [
        {
          name: 'Dashboard',
          route: '/dashboard',
          image: '../../../assets/images/dashboard-ico.svg',
          iconClass: 'icon-dashboard',
          links: [],
        },
        {
          label: 'Inbound',
          name: 'Inbound',
          route: '/inbound/purchase-request',
          image: '../../../assets/images/inbound-ico.svg',
          iconClass: 'icon-inbound',
          links: [
            {
              name: 'Purchase Request',
              route: '/inbound/purchase-request',
              image: '',
              links: [],
            },
            {
              name: 'Inbound Orders',
              route: '/inbound/purchase-order',
              image: '',
              links: [],
            },
            {
              name: 'ASN',
              route: '/inbound/asn',
              image: '',
              links: [],
            },
          ],
        },
        // {
        //   label: 'Receiving',
        //   name: 'Receiving',
        //   route: '/receiving/unload',
        //   image: '../../../assets/images/Group (1).png',
        //   iconClass: 'icon-receiving',
        //   links: [
        //     {
        //       name: 'Unload',
        //       route: '/receiving/unload',
        //       image: '',
        //       links: [],
        //     },
        //     {
        //       name: 'Receive',
        //       route: '/receiving/receive',
        //       image: '',
        //       links: [],
        //     },
        //     {
        //       name: 'Direct Receiving',
        //       route: '/receiving/unload-receive',
        //       image: '',
        //       links: [],
        //     },
        //   ],
        // },
        {
          label: 'Inventory',
          name: 'Inventory',
          route: '/inventory/overview',
          image: '../../../assets/images/Vector (8).png',
          iconClass: 'icon-inventory',
          links: [
            {
              name: 'Overview',
              route: '/inventory/overview',
              image: '',
              links: [],
            },
            {
              name: 'Adjustments',
              route: '/inventory/inventory-adjustments',
              image: '',
              links: [],
            },
          ],
        },
        {
          label: 'Outbound',
          name: 'Outbound',
          route: '/outbound/sales-requests',
          image: '../../../assets/images/Vector (7).png',
          iconClass: 'icon-outbound',
          links: [
            {
              name: 'Sales Requests',
              route: '/outbound/sales-requests',
              image: '',
              links: [],
            },
            {
              name: 'Outbound Orders',
              route: '/outbound/sales-orders',
              image: '',
              links: [],
            },
          ],
        },
        {
          label: 'Fulfillment',
          name: 'Fulfillment',
          route: '/fulfillment/fulfillment-requests',
          image: '../../../assets/images/Vector (7).png',
          iconClass: 'icon-fulfillment',
          links: [
            {
              name: 'Fulfillment Requests',
              route: '/fulfillment/fulfillment-requests',
              image: '',
              links: [],
            },

            // {
            //   name: 'Shipment Requests',
            //   route: '/fulfillment/shipment-requests',
            //   image: '',
            //   links: [
            //   ]
            // }
          ],
        },
        {
          label: 'Setup',
          name: 'Setup',
          route: '/setup/product-catalog',
          image: '../../../assets/images/Vector (5).png',
          iconClass: 'icon-settings',
          links: [
            {
              name: 'SKUs',
              route: '/setup/product-catalog',
              image: '',
              links: [],
            },
            {
              name: 'Customers',
              route: '/setup/customers',
              image: '',
              links: [],
            },
            {
              name: 'Vendors',
              route: '/setup/vendors',
              image: '',
              links: [],
            },
            {
              name: 'Users',
              route: '/setup/users',
              image: '',
              links: [],
            },
            {
              name: 'Roles',
              route: '/setup/roles',
              image: '',
              links: [],
            },
            {
              name: 'Lookups',
              route: '/setup/lookup',
              image: '',
              links: [],
            },
          ],
        },
      ],
    },
  ];

  public MenuDataServiceProvider: any;

  currentRoute: any;
  activeWarehouse = false;

  constructor(
    public service: ConfigService,
    public router: Router,
    private route: Router,
    public alert: AlertService,
    public ngxLoader: NgxUiLoaderService,
    public route1: ActivatedRoute,
  ) {
    this.getMenu();
    // this.getPermissions();

    //temporary solution for not adding irrevelant param in url
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        ////  console.log(ev.url, "url")
        if (ev.url == '/setup/areas/list' || ev.url == '/setup/location/list' || ev.url == '/setup/parent-area/list') {
          this.route.navigate(['/dashboard']);
        }

        if (
          ev.url.startsWith(`/setup/areas`) ||
          ev.url.startsWith(`/setup/location`) ||
          ev.url.startsWith(`/setup/parent-area`)
        ) {
          this.activeWarehouse = true;
        } else {
          this.activeWarehouse = false;
        }

        let param = ev.url.split('/')[2];
        if (this.service.accountTypeAPI == 'wms_service') {
          let authParam = this.service.spRoles[0].restrictedPages.find(
            (x: any) => x == param
          );
          if (authParam) {
            this.route.navigate(['/dashboard']);
            this.alert.show('Page not found', 'error');
          }
        } else if (this.service.accountTypeAPI == 'client_service') {
          let authParam = this.service.spRoles[1].restrictedPages.find(
            (x: any) => x == param
          );
          if (authParam) {
            this.route.navigate(['/dashboard']);
            this.alert.show('Page not found', 'error');
          }
        }
      }
    });
    
  }

  async getMenu() {
    this.ngxLoader.start()
    this.route1.data.subscribe(data => {
      if (data['menu'] && data['menu'].status && data['menu'].data) {
        this.masterData = data['menu'].data.menu;
        this.MenuDataServiceProvider = this.masterData;
        this.menuDataUpdate();
      }
      this.data = data['data']; // Access other resolved data here
    });
    this.ngxLoader.stop()
  }

  menuDataUpdate() {
    let FilteredMenuDataServiceProvider: any = [];
    if (this.service.profileDetail?.account_type === 'service_provider') {
      for (
        let index = 0;
        index < this.MenuDataServiceProvider[0].links.length;
        index++
      ) {
        this.MenuDataServiceProvider[0].links[index].links =
          this.MenuDataServiceProvider[0].links[index].links.filter(
            (value: any) => Object.keys(value).length !== 0
          );
      }
      for (
        let index = 0;
        index < this.MenuDataServiceProvider[0].links.length;
        index++
      ) {
        if (
          this.MenuDataServiceProvider[0].links[index].name == 'Dashboard' ||
          this.MenuDataServiceProvider[0].links[index].links.length > 0
        ) {
          FilteredMenuDataServiceProvider.push(
            this.MenuDataServiceProvider[0].links[index]
          );
        }
      }
      this.MenuDataServiceProvider[0].links = FilteredMenuDataServiceProvider;
      this.MenuData = this.MenuDataServiceProvider;
      console.log('Menu data', this.MenuData);
    } else if (this.service.profileDetail?.account_type === 'client') {
      // this.MenuDataClient[0].links = this.MenuDataClient[0].links.filter(value => Object.keys(value).length !== 0);
      this.MenuData = this.MenuDataClient;
    } else if (this.service.profileDetail?.account_type === 'admin') {
      // this.MenuDataClient[0].links = this.MenuDataClient[0].links.filter(value => Object.keys(value).length !== 0);
      this.MenuData = this.MenuDataAccount;
    }
  }
  calculatePadding() {
    return this.isCollapsed ? '60px' : '20rem';
  }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  ngOnInit(): void {
    // console.log(this.isSidebarOpen, 'this.isSidebarOpen');
    // console.log('this.service.permissions', this.MenuDataClient);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url) {
        this.currentRoute = event.url;
        this.openMenuForCurrentRoute();
      }
    });
    this.currentRoute = this.router.url; 
    this.openMenuForCurrentRoute();
  }
 
  openMenuForCurrentRoute() {
    const openMenu = (menuItems: any[], url: string): boolean => {
      const strippedUrl = this.removeLastSegment(url);
      for (const item of menuItems) {
        if (item.route === url) {
          item.isOpen = true;  
          return true;
        }
        if (strippedUrl.startsWith(item.route)) { // matching the current route
          item.isOpen = true;
          return true;
        }
        if (item.links && item.links.length > 0) {
          console.log("item",);
          if (openMenu(item.links, url)) {
            item.isOpen = true;
            return true;
          }
        }
      }
      return false;
    };
    if (this.MenuData && this.MenuData[0] && this.MenuData[0].links) {
      openMenu(this.MenuData[0].links, this.currentRoute);
    }
  }
    removeLastSegment(url: string): string {
      const segments = url.split('/');
      segments.pop();
      return segments.join('/');
    }
  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('rememberMe');
    this.route.navigate(['login']);
  }
  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    console.log(this.isSidebarOpen, 'this.isSidebarOpen Toggle');
  }
  closeSidebar(): void {
    this.isSidebarOpen = false;
  }
  handleSidebarItemClick(): void {
    this.closeSidebar();
  }

  // async getPermissions() {
  //   const response: any = await this.service.getApi('/api/user/permissions', {}, {}, 'auth_service');
  //   if (response.data.permissions) {
  //     this.service.permissions = response.data.permissions.formatted_permissions;
  //     localStorage.setItem('permissions', JSON.stringify(response.data.permissions.formatted_permissions));
  //     this.service.setPermissions();
  //   }
  // }

  checkPermission(item: any) {
    console.log(item);
    return true;
  }

  sptoggle(event: any) {
    this.menuStartingIndex = event;
  }

  onToggleBtnClick() {
    this.hideSideMenu = !this.hideSideMenu;
  }

  routeToFirstNestedItem(routeTo: any) {
    this.route.navigate([routeTo]);
  }

  routeToList(routeTo: any) {
    this.route.navigate([routeTo]);
  }
  openListAllStatus() {
    this.service.selectedIndexForGridTabs = 0;
  }
  toggleMenu(item: any) {
    if (item.name === 'Dashboard') {
      this.navigateTo(item.route); // Navigate to the Dashboard
    } else {
      item.isOpen = !item.isOpen; // Toggle the submenu
     
    }
  }
  
  navigateTo(route: any) {
    this.route.navigate([route]);
  }

}
